<template>
  <div>
    <header class="fd-app-platform-header">
      <div
        class="fd-app-platform-header-bg"
        :style="{ backgroundImage: 'url(' + fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-platform-header-overlay">
        <div class="container">
          <h1 v-if="fastTipoCurso.curso_livre">
            {{fastPlataforma.nome_plataforma + ' - '}} Cursos livres 
          </h1>
          <h1 v-else>
            {{fastPlataforma.nome_plataforma + ' - '}} Cursos da secretaria 
          </h1>
        </div>
      </div>
    </header>
    <!-- Body -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul
            id="breadcrumb"
            class="fd-app-breadcrumb"
          />
        </nav>
        <!-- /breadcrumb -->
        <!-- fd-modes -->
        <section class="fd-modes">

          <!-- fd-cursos-ondemand -->
          <section class="fd-marketplace">
            <div class="fd-marketplace-content">
              <div class="fd-marketplace-search">  
                <div class="fd-marketplace-search-form pb-0">
                  <strong>Tipo de curso</strong>
                  <select v-if="fastTipoCurso.curso_livre" class="form-control" @change="alteraTipoCurso">                    
                    <option value="L" checked>Cursos livres</option>    
                    <option value="S">Cursos da secretaria</option>                
                  </select>
                  <select v-else class="form-control" @change="alteraTipoCurso">                    
                    <option value="L">Cursos livres</option>    
                    <option value="S" checked>Cursos da secretaria</option>                
                  </select>
                </div>

                <div v-if="fastTipoCurso.curso_livre" class="fd-marketplace-search-form">
                  <strong>Filtrar Cursos livres</strong>
                  <div class="position-relative">
                    <Autocomplete
                      :get-label="getLabel"
                      :input-attrs="{ class: 'form-control filtro-cursos-ondemand', placeholder: 'Procurar curso' }"
                      :items="fastCursosFiltro"
                      :component-item="CursosAutoComplete"
                      @update-items="updateItems"
                      @item-clicked="clickCurso"
                    />
                  </div>
                  <select
                    v-if="fastSegmentos.length"
                    id="selSegmentoCursoFiltro"
                    class="form-control filtro-cursos-ondemand filtro-cursos-ondemand-segmento"
                    @change="filtraCursoSegmento($event)"
                  >
                    <option value="0">
                      Todos os segmentos
                    </option>
                    <option
                      v-for="segmento in fastSegmentos"
                      :key="segmento.id_segmento"
                      :value="segmento.id_segmento"
                    >
                      {{ segmento.nome_segmento }}
                    </option>
                  </select>
                  <select
                    v-if="fastPlataformaDisciplinas.length"
                    id="selDisciplinaCursoFiltro"
                    class="form-control filtro-cursos-ondemand filtro-cursos-ondemand-disciplina"
                    @change="filtraCursoDisciplina($event)"
                  >
                    <option value="0">
                      Todos os disciplinas
                    </option>
                    <option
                      v-for="disciplina in fastPlataformaDisciplinas"
                      :key="disciplina.id_plataforma_area_disciplina"
                      :value="disciplina.id_plataforma_area_disciplina"
                    >
                      {{ disciplina.descricao }}
                    </option>
                  </select>
                </div>
                <div v-else class="fd-marketplace-search-form"></div>
              </div>
              <div class="fd-cursos-ondemand">
                <div class="fd-cursos-ondemand-content">
                  <h2
                    data-resultados-filtro-cursos
                    style="display: none;"
                  >
                    Resultados
                  </h2>
                  <div
                    v-if="fastTipoCurso.curso_livre"
                    style="min-height: 300px;"
                  >
                    <div class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Todos os cursos livres ({{ fastCursosFiltro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in pageOfCursos"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6 v-if="curso.id_item_cobranca && curso.valor_item">
                                      R$ {{ formataPreco(curso.valor_item) }}
                                    </h6>
                                    <h6 v-else>
                                      Sob demanda
                                    </h6>
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">
                                <div
                                  v-if="curso.carrinho"
                                  class="mb-2"
                                >
                                  <strong class="text-secondary">No carrinho</strong>
                                </div>
                                <div v-else>
                                  <button
                                    class="btn btn-success-m"
                                    @click="adicionaCarrinho(curso)"
                                  >
                                    + Carrinho
                                  </button>
                                </div>
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_curso"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="fastCursosFiltro > 10" class="col-12 text-center mt-4">
                        <Pagination                        
                          :page-size="10"
                          :items="fastCursosFiltro"
                          @changePage="pageOfCursos = $event"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="fastTipoCurso.curso_secretaria">

                    <!-- Cursos da secretaria - fundamental -->
                    <div v-if="fastSecretariaCursosFundamental.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Ensino fundamental ({{ fastSecretariaCursosFundamental.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosFundamental.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosFundamental.filtro"
                          @changePage="fastSecretariaCursosFundamental.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - médio -->
                    <div v-if="fastSecretariaCursosMedio.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Ensino médio ({{ fastSecretariaCursosMedio.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosMedio.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosMedio.filtro"
                          @changePage="fastSecretariaCursosMedio.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - eja -->
                    <div v-if="fastSecretariaCursosEja.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>EJA ({{ fastSecretariaCursosEja.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosEja.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosEja.filtro"
                          @changePage="fastSecretariaCursosEja.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - técnico -->
                    <div v-if="fastSecretariaCursosTecnico.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Ensino técnico ({{ fastSecretariaCursosTecnico.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosTecnico.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosTecnico.filtro"
                          @changePage="fastSecretariaCursosTecnico.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - superior -->
                    <div v-if="fastSecretariaCursosSuperior.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Ensino superior ({{ fastSecretariaCursosSuperior.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosSuperior.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosSuperior.filtro"
                          @changePage="fastSecretariaCursosSuperior.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - pós -->
                    <div v-if="fastSecretariaCursosPos.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Pós-graduação ({{ fastSecretariaCursosPos.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosPos.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosPos.filtro"
                          @changePage="fastSecretariaCursosPos.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - mestrado -->
                    <div v-if="fastSecretariaCursosMestrado.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Mestrado ({{ fastSecretariaCursosMestrado.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosMestrado.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosMestrado.filtro"
                          @changePage="fastSecretariaCursosMestrado.paginacao = $event"
                        />
                      </div>
                    </div>

                    <!-- Cursos da secretaria - doutorado -->
                    <div v-if="fastSecretariaCursosDoutorado.total.length" class="container-segmento">
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Doutorado ({{ fastSecretariaCursosDoutorado.filtro.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="curso in fastSecretariaCursosDoutorado.paginacao"
                          :key="curso.id_curso"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ curso.nome_curso }}</strong>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">
                            <div
                              v-if="curso.logo_curso"
                              v-lazy:background-image="curso.logo_curso"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div
                              v-else
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>
                                      R$ {{ formataPreco(curso.preco) }}
                                    </h6>                                    
                                  </li>
                                  <li>
                                    <b>{{ curso.carga_horaria }} horas</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">  
                                <a
                                  :href="'/loja-virtual/' + $route.params.id_plataforma + '/curso/' + curso.id_secretaria_curso + '?secretaria'"
                                  class="btn btn-info btn-sm"
                                >Ver detalhes</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="10"
                          :items="fastSecretariaCursosDoutorado.filtro"
                          @changePage="fastSecretariaCursosDoutorado.paginacao = $event"
                        />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              
            </div>
          </section>

          <!-- Promoções -->
          <section
            v-if="fastLojaPromo.length"
            class="fd-marketplace d-none"
          >
            <div class="fd-marketplace-content">
              <div class="fd-marketplace-search" />
              <div class="fd-cursos-ondemand">
                <div class="fd-cursos-ondemand-content">
                  <div
                    id="divListaResultadoCursosOnDemand"
                    style="min-height: 300px;"
                  >
                    <div
                      id="container-segmento-1"
                      class="container-segmento"
                    >
                      <div class="d-flex align-items-center">
                        <h2>
                          <span>Promoções ({{ fastLojaPromo.length }})</span>
                        </h2>
                      </div>
                      <div class="fd-cursos-ondemand-content-list block">
                        <div
                          v-for="promo in fastLojaPromo"
                          :key="promo.id_promo"
                          class="fd-cursos-ondemand-content-list-item col-sm-12 col-md-12 col-lg-4"
                        >
                          <div class="fd-cursos-ondemand-content-list-item-header">
                            <strong>{{ promo.nome_promo }}</strong>
                            <p>{{ promo.descricao_promo }}</p>
                          </div>
                          <div class="fd-cursos-ondemand-content-list-item-body">                           
                            <div
                              v-lazy:background-image="require('@/assets/images/app/plataforma-img.jpg')"
                              class="fd-cursos-ondemand-content-list-item-body-image"
                            />
                            <div class="fd-cursos-ondemand-content-list-item-body-footer">
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-info">
                                <ul>
                                  <li>
                                    <h6>R$ {{ promo.valor_avista.toFixed(2) }}</h6>
                                  </li>
                                  <li>
                                    <b>Em até {{ promo.limite_parcelas }} x</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="fd-cursos-ondemand-content-list-item-body-footer-actions">                                
                                <div>
                                  <a 
                                    :href="promo.url"
                                    class="btn btn-success-m"
                                  >+ Carrinho</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="fd-cursos-ondemand-content-pagination pc">
                    <div id="pagination-container" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          

          

        </section>
        <!-- /fd-modes -->
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "./components/Pagination";
import Autocomplete from "v-autocomplete";
import CursosAutoComplete from "./components/CursosAutoComplete";

export default {
  name: "LojaVirtualContent",
  components: {
    Pagination,
    Autocomplete
  },
  mixins: [methods],
  data: function () {
    return {
      idPlataformaExterno: settings.idPlataformaExterno,
      //fastPlataforma
      fastPlataforma: {
        banner_plataforma: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        url_plataforma: "",
      },
      // fastCursos
      fastCursosTotal: [],
      fastCursosFiltro: [],
      pageOfCursos: [],
      CursosAutoComplete,
      // Filtro
      fastSegmentos: [],
      fastFornecedores: [],
      fastTags: [],
      fastPlataformaDisciplinas: [],
      fastTipoCurso: {
        curso_livre: false,
        curso_secretaria: true,
        fundamental: false,
        medio: false,
        eja: false,
        tecnico: false,
        superior: false,
        pos: false,
        mestrado: false,
        doutorado: false
      },
      // Loja promo
      fastLojaPromo: [],
      // Cursos técnicos
      fastSecretariaCursosFundamental: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosMedio: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosEja: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosTecnico: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosSuperior: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosPos: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosMestrado: {
        total: [],
        filtro: [],
        paginacao: []
      },
      fastSecretariaCursosDoutorado: {
        total: [],
        filtro: [],
        paginacao: []
      },
    };
  },
  mounted() {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      this.getPlataformaData(this.$route.params.id_plataforma);
      if (this.getUrlParameter('secretaria')) {
        this.fastTipoCurso.curso_secretaria = true
        this.fastTipoCurso.curso_livre = false
      } else {
        this.fastTipoCurso.curso_secretaria = false
        this.fastTipoCurso.curso_livre = true
      }
    }
  },
  methods: {
    alteraTipoCurso(event){
      if (event.target.value == "L") {
        this.fastTipoCurso.curso_livre = true
        this.fastTipoCurso.curso_secretaria = false
      } else {
        this.fastTipoCurso.curso_secretaria = true
        this.fastTipoCurso.curso_livre = false
      }
    },
    async getPlataformaData(id_plataforma) {
      this.promiseGetFastApi( "api/fast_plataforma/lista_detalhes_cliente", "id_plataforma=" + id_plataforma).then(res => {
        if (res.length) {
          console.log("getPlataformaData", res)
          this.fastPlataforma = res[0]
          if (this.idPlataformaExterno == id_plataforma) {
            return this.getFastLojaCursosFastead(
              0,
              "",
              "",
              "",
              "",
              "",
              id_plataforma
            );
          } else {
            return this.getFastLojaCursos(
              0,
              "",
              "",
              "",
              "",
              "",
              id_plataforma
            );
          }
        } else {
          this.exibeToasty("Erro ao buscar plataforma", "error")
        }
      })
      .then(() => {
        this.getFastLojaPromoVitrine();
        this.getSecretariaCursos(id_plataforma)
      })
      .catch(e => {
        console.log(e);
        this.fastItensCobranca.loading = false
      })

    },
    async getFastLojaCursosFastead(
      id_curso,
      ids_plataforma_area_disciplina,
      nome_curso,
      nome_tags,
      ids_fornecedores,
      ids_segmentos,
      id_plataforma
    ) {
      this.fastCursosTotal = [];
      this.fastCursosFiltro = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/loja_virtual_fastead?id_curso=" +
            id_curso +
            "&ids_plataforma_area_disciplina=" +
            ids_plataforma_area_disciplina +
            "&nome_curso=" +
            nome_curso +
            "&nome_tags=" +
            nome_tags +
            "&ids_fornecedores=" +
            ids_fornecedores +
            "&ids_segmentos=" +
            ids_segmentos +
            "&id_plataforma=" +
            id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        if (obj.length) {
          obj.forEach((c) => {
            c.preco = "";
            let incluidoCarrinho = false;
            if (this.$store.state.fastCarrinho.length) {
              this.$store.state.fastCarrinho.forEach((p) => {
                if (c.id_curso == p.id_curso) incluidoCarrinho = true;
              });
            }
            c.carrinho = incluidoCarrinho;
          });
          obj.forEach((c) => {
            if (c.id_produto_vindi) {
              this.getCursoPrecoVindi(c);
            } else {
              this.fastCursosTotal.push(c);
              this.fastCursosFiltro.push(c);
            }
          });
        } else {
          this.fastCursosTotal = [];
          this.fastCursosFiltro = [];
        }
        this.getFastSegmentos();
        this.getFastFornecedores();
        this.getFastTags();
        this.getFastPlataformaDisciplinas();

        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastLojaCursos(
      id_curso,
      ids_plataforma_area_disciplina,
      nome_curso,
      nome_tags,
      ids_fornecedores,
      ids_segmentos,
      id_plataforma
    ) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/loja_virtual_plataforma?id_curso=" +
            id_curso +
            "&ids_plataforma_area_disciplina=" +
            ids_plataforma_area_disciplina +
            "&nome_curso=" +
            nome_curso +
            "&nome_tags=" +
            nome_tags +
            "&ids_fornecedores=" +
            ids_fornecedores +
            "&ids_segmentos=" +
            ids_segmentos +
            "&id_plataforma=" +
            id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log("getFastLojaCursos", json)
        let obj = Array.from(json);        
        if (obj.length) {
          obj.forEach((c) => {
            c.preco = "";
            let incluidoCarrinho = false;
            if (this.$store.state.fastCarrinho.length) {
              this.$store.state.fastCarrinho.forEach((p) => {
                if (c.id_curso == p.id_curso) incluidoCarrinho = true;
              });
            }
            c.carrinho = incluidoCarrinho;
          });
          this.fastCursosTotal = obj;
          this.fastCursosFiltro = obj;
        } else {
          this.fastCursosTotal = [];
          this.fastCursosFiltro = [];
        }
        this.getFastSegmentos();
        this.getFastFornecedores();
        this.getFastTags();
        this.getFastPlataformaDisciplinas();
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getCursoPrecoVindi(curso) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_produto_vindi?id_produto_vindi=" +
            curso.id_produto_vindi,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        if (JSON.parse(json).product)
          curso.preco = JSON.parse(json).product.pricing_schema.short_format;
        this.fastCursosTotal.push(curso);
        this.fastCursosFiltro.push(curso);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getSecretariaCursos(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getSecretariaCursos", res);
        if (res.length) {
          this.fastSecretariaCursosFundamental.total = res.filter(e => e.nivel == "F")
          this.fastSecretariaCursosFundamental.filtro = res.filter(e => e.nivel == "F")
          this.fastSecretariaCursosMedio.total = res.filter(e => e.nivel == "E")
          this.fastSecretariaCursosMedio.filtro = res.filter(e => e.nivel == "E")
          this.fastSecretariaCursosEja.total = res.filter(e => e.nivel == "J")
          this.fastSecretariaCursosEja.filtro = res.filter(e => e.nivel == "J")
          this.fastSecretariaCursosTecnico.total = res.filter(e => e.nivel == "T")
          this.fastSecretariaCursosTecnico.filtro = res.filter(e => e.nivel == "T")
          this.fastSecretariaCursosSuperior.total = res.filter(e => e.nivel == "S")
          this.fastSecretariaCursosSuperior.filtro = res.filter(e => e.nivel == "S")
          this.fastSecretariaCursosPos.total = res.filter(e => e.nivel == "P")
          this.fastSecretariaCursosPos.filtro = res.filter(e => e.nivel == "P")
          this.fastSecretariaCursosMestrado.total = res.filter(e => e.nivel == "M")
          this.fastSecretariaCursosMestrado.filtro = res.filter(e => e.nivel == "M")
          this.fastSecretariaCursosDoutorado.total = res.filter(e => e.nivel == "D")     
          this.fastSecretariaCursosDoutorado.filtro = res.filter(e => e.nivel == "D")  
          
        }
        else { 
          this.fastSecretariaCursosFundamental.total = []
          this.fastSecretariaCursosFundamental.filtro = []
          this.fastSecretariaCursosMedio.total = []
          this.fastSecretariaCursosMedio.filtro = []
          this.fastSecretariaCursosEja.total = []
          this.fastSecretariaCursosEja.filtro = []
          this.fastSecretariaCursosTecnico.total = []
          this.fastSecretariaCursosTecnico.filtro = []
          this.fastSecretariaCursosSuperior.total = []
          this.fastSecretariaCursosSuperior.filtro = []
          this.fastSecretariaCursosPos.total = []
          this.fastSecretariaCursosPos.filtro = []
          this.fastSecretariaCursosMestrado.total = []
          this.fastSecretariaCursosMestrado.filtro = []
          this.fastSecretariaCursosDoutorado.total = []
          this.fastSecretariaCursosDoutorado.filtro = []
        }
      }).catch(e => {
        console.log(e);
        this.fastCategorias.loading = false
      })
    },
    // Autocomplete
    getLabel(item) {
      return item.nome_curso;
    },
    // Autocomplete
    updateItems(text) {
      //console.log(text);
      this.fastCursosFiltro = this.fastCursosTotal.filter(
        (e) => e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    // Autocomplete
    clickCurso(text) {
      this.fastCursosFiltro = [];
      this.fastCursosFiltro.push(text);
    },
    // Filtro
    async getFastSegmentos() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_segmento/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastSegmentos = obj;
        }
        //console.log("getFastSegmentos", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastFornecedores() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_fornecedor/lista_todos",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastFornecedores = obj;
        }
        //console.log("getFastFornecedores", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastTags() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_curso_tag/lista_todas",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastTags = obj;
        }
        //console.log("getFastTags", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastPlataformaDisciplinas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_disciplinas/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaDisciplinas = obj;
        }
        //console.log("getFastPlataformaDisciplinas", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraCursoSegmento(segmento) {
      if (segmento.target.value == 0) {
        this.fastCursosFiltro = this.fastCursosTotal;
      } else {
        this.fastCursosFiltro = this.fastCursosTotal.filter((e) => {
          if (e.segmentos) {
            if (e.segmentos.split(",").length > 1) {
              Array.from(e.segmentos.split(",")).forEach((s) => {
                if (s.id_segmento == segmento.target.value) return true;
              });
            } else {
              if (e.segmentos == segmento.target.value) return true;
            }
          }
        });
      }
    },
    filtraCursoDisciplina(disciplina) {
      if (disciplina.target.value == 0) {
        this.fastCursosFiltro = this.fastCursosTotal;
      } else {
        this.fastCursosFiltro = this.fastCursosTotal.filter((e) => {
          if (e.disciplina) {
            if (e.disciplina.split(",").length > 1) {
              Array.from(e.disciplina.split(",")).forEach((s) => {
                if (s.id_plataforma_area_disciplina == disciplina.target.value)
                  return true;
              });
            } else {
              if (e.disciplina == disciplina.target.value) return true;
            }
          }
        });
      }
    },
    // Carrinho
    adicionaCarrinho(curso) {
      if (this.$store.state.fastCarrinho.length) {
        let incluido = false;
        this.$store.state.fastCarrinho.forEach((p) => {
          if (curso.id_curso == p.id_curso) incluido = true;
        });
        if (!incluido) {
          this.$store.state.fastCarrinho.push(curso);
          curso.carrinho = true;
        }
      } else {
        this.$store.state.fastCarrinho.push(curso);
        curso.carrinho = true;
      }
      this.setFastSessao(
        settings.fastSessaoCarrinho,
        this.$store.state.fastCarrinho
      );
      window.location.href =
        "/loja-virtual/" +
        this.$store.state.fastPermissoes.id_plataforma +
        "/carrinho";
    },
    // Loja promo
    async getFastLojaPromoVitrine() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_loja_promo/lista_vitrine?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        
        let obj = Array.from(json);
        if (obj.length) {         
          this.fastLojaPromo = obj;
        } else {
          this.fastLojaPromo = [];
        }
        console.log("fastLojaPromo", this.fastLojaPromo);
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scoped>
.fd-app-platform-header .fd-app-platform-header-bg {
  z-index: -1;
}
</style>
